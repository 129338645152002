import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=a83c257e&scoped=true"
import script from "./Article.vue?vue&type=script&lang=js"
export * from "./Article.vue?vue&type=script&lang=js"
import style0 from "./Article.vue?vue&type=style&index=0&id=a83c257e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a83c257e",
  null
  
)

export default component.exports